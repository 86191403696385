<template>
  <v-container fluid>
    <div class="text-right mb-2 mt-5"></div>
    <div class="">
      <v-flex class="py-2 px-3 tableHeader">{{
        $vuetify.lang.t("$vuetify.posted_job")
      }}</v-flex>

      <v-form
        style="width: 100%"
        class="tableSearch"
        @submit.prevent="searchData"
      >
        <v-row align="center" class="ma-0">
          <v-col cols="5">
            <v-text-field
              :label="$vuetify.lang.t('$vuetify.search')"
              v-model="search"
              class="mx-2"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-select
              :items="searchable_items"
              :item-text="'text'"
              :item-value="'search'"
              v-model="search_field"
              :label="$vuetify.lang.t('$vuetify.search_from_column')"
              class="mx-2"
              disable-initial-sort
              hide-details
            >
            </v-select>
          </v-col>
          <v-col cols="2">
            <v-btn type="submit" outlined block color="secondary">{{
              $vuetify.lang.t("$vuetify.search")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <v-data-table
      :headers="headers"
      :items="formatedList"
      :loading="loading"
      :options="paginationOptions"
      :server-items-length="totalCount"
      @pagination="someChange"
      item-key="id"
      :loading-text="$vuetify.lang.t('$vuetify.loading_please_wait')"
      :no-results-text="$vuetify.lang.t('$vuetify.no_data_found')"
      :no-data-text="$vuetify.lang.t('$vuetify.no_data_available')"
      class="elevation-1"
    >
      <template v-slot:[`item.title`]="{ item }">
        <span
          style="font-weight: bold; cursor: pointer"
          @click="moveToTitle(item)"
          >{{ item.title }}</span
        >
      </template>

      <template v-slot:[`item.location`]="{ item }">
        <span>{{ formatAllLocations(item.location) }}</span>
        <v-tooltip
          top
          color="primary"
          v-if="showLocationsTooltip(item.location)"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary" small class="mx-1"
              >mdi-information</v-icon
            >
          </template>
          <span>{{ showLocationsTooltip(item.location) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.hired_days`]="{ item }">
        <span class="secondary--text" v-if="!item.is_interviewer">
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="success--text">{{
                item.hired.joined
              }}</span>
              /
            </template>
            <span>Filled Jobs</span>
          </v-tooltip>

          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="error--text">{{
                item.hired.positions
              }}</span>
            </template>
            <span>Total Jobs</span>
          </v-tooltip>

          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="ml-2">
                ({{ item.hired.days }} days)
              </span>
            </template>
            <span>Time to hire</span>
          </v-tooltip>
        </span>
        <span v-else>n/a</span>
      </template>

      <template v-slot:[`item.new_applications`]="{ item }">
        <router-link
          v-if="!item.is_interviewer"
          class=""
          :to="`/posted-jobs/new/${item.id}`"
        >
          {{ item.new_applications }}
        </router-link>
        <span v-else>n/a</span>
      </template>

      <template v-slot:[`item.total_applications`]="{ item }">
        <router-link
          v-if="!item.is_interviewer"
          class=""
          :to="`/posted-jobs/total/${item.id}`"
        >
          {{ item.total_applications }}
        </router-link>
        <span v-else>n/a</span>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <template v-if="!item.is_interviewer">
          <v-btn
            icon
            outlined
            @click="makeConfirmation(item, 'delete')"
            color="secondary"
            class="mr-2"
            small
          >
            <v-icon small color="secondary"> mdi-delete </v-icon>
          </v-btn>
          <v-btn
            icon
            outlined
            @click="makeConfirmation(item, 'edit')"
            color="secondary"
            small
          >
            <v-icon small color="secondary"> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span v-else>--</span>
      </template>

      <template v-slot:[`item.link`]="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-bind="attrs" v-on="on"> Share </v-btn>
          </template>
          <v-list>
            <v-list-item @click="shareSocialMedia(item, 'facebook')">
              <v-list-item-title>Facebook</v-list-item-title>
            </v-list-item>

            <v-list-item @click="shareSocialMedia(item, 'linkedin')">
              <v-list-item-title>LinkedIn</v-list-item-title>
            </v-list-item>

            <v-list-item @click="shareSocialMedia(item, 'email')">
              <v-list-item-title>Email</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-clipboard="`${item.link}?emp_id=${user._id}`"
              v-clipboard:success="clipboardSuccessHandler"
              v-clipboard:error="clipboardErrorHandler"
            >
              <v-list-item-title>Copy Link</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <template v-if="false">
          <v-btn
            icon
            outlined
            color="secondary"
            @click="shareSocialMedia(item, 'facebook')"
            class="mr-2"
            small
          >
            <v-icon small color="secondary"> mdi-facebook </v-icon>
          </v-btn>

          <v-btn
            icon
            outlined
            color="secondary"
            @click="shareSocialMedia(item, 'linkedin')"
            class="mr-2"
            small
          >
            <v-icon small color="secondary"> mdi-linkedin </v-icon>
          </v-btn>

          <v-btn
            icon
            outlined
            color="secondary"
            @click="shareSocialMedia(item, 'email')"
            class="mr-2"
            small
          >
            <v-icon small color="secondary"> mdi-email </v-icon>
          </v-btn>

          <v-btn
            icon
            outlined
            color="secondary"
            v-clipboard="`${item.link}?emp_id=${user._id}`"
            v-clipboard:success="clipboardSuccessHandler"
            v-clipboard:error="clipboardErrorHandler"
            small
          >
            <v-icon small color="secondary"> mdi-clipboard-text </v-icon>
          </v-btn>
        </template>
      </template>
    </v-data-table>

    <!-- confirmationhandler -->
    <v-dialog v-model="showConfirmationModal" max-width="500px">
      <v-card class="pa-2">
        <v-card-title
          ><span class="headline">
            {{
              $vuetify.lang.t(
                `$vuetify.popup.posted_job.${
                  modelType === "delete" ? "delete_job" : "edit"
                }`
              )
            }}
          </span></v-card-title
        >
        <v-card-actions class="text-right">
          <v-btn
            color="secondary"
            class="mx-3"
            outlined
            @click="showConfirmationModal = false"
          >
            {{ $vuetify.lang.t("$vuetify.cancel") }}
          </v-btn>

          <v-btn
            @click="confirmHandler"
            :loading="btnLoading"
            v-if="modelType"
            color="secondary"
            class="mx-0 elevation-0"
          >
            {{ $vuetify.lang.t(`$vuetify.${modelType}`) }}
            {{ $vuetify.lang.t("$vuetify.job") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- invite candidate by email -->
    <v-dialog v-model="invitecandidate" max-width="600px">
      <v-card>
        <v-card-text class="pa-3 py-4">
          <template>
            <div xs12 style="width: 100%">
              <h3>
                {{
                  $vuetify.lang.t(
                    "$vuetify.popup.social_share.invite_candidate"
                  )
                }}
              </h3>
              <p>
                {{ $vuetify.lang.t("$vuetify.popup.social_share.example") }}
                <b>xyz@gmail.com,abc@gmail.com </b>
              </p>
              <v-form
                v-on:submit.prevent="sendCandidateInvitation"
                xs12
                style="width: 100%"
              >
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.candidate_emails')"
                  required
                  class="mr-2"
                  v-model="emails"
                  :rules="fieldRules"
                >
                </v-text-field>

                <v-flex class="text-sm-center my-3">
                  <v-btn
                    type="submit"
                    class="mr-2"
                    :loading="btnLoading"
                    color="secondary"
                  >
                    {{ $vuetify.lang.t("$vuetify.save_changes") }}
                  </v-btn>
                  <v-btn
                    outlined
                    @click="invitecandidate = false"
                    class="buttonPrimary text-lightblue close-modal"
                  >
                    {{ $vuetify.lang.t("$vuetify.cancel") }}
                  </v-btn>
                </v-flex>
              </v-form>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- cannot delete post -->
    <v-dialog v-model="cannotDeleteModal" max-width="500px">
      <v-card class="pa-2">
        <v-card-title
          ><span class="headline">
            You cannot delete this job post because it has more then one profile
          </span></v-card-title
        >
        <v-card-actions class="text-right">
          <v-btn
            color="secondary"
            class="mx-3"
            outlined
            @click="cannotDeleteModal = false"
          >
            {{ $vuetify.lang.t("$vuetify.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- snackbar -->
    <v-snackbar
      :timeout="2000"
      v-model="snackbar"
      :color="isError ? 'error' : 'success'"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { UTC_TO_LOCAL } from "@/helpers";

import userRestriction from "@/mixins/user-restriction.js";
export default {
  name: "posted-jobs",
  mixins: [userRestriction],
  data() {
    return {
      loading: false,
      items: [],
      search_field: "",
      search: "",
      //   pagination
      totalCount: 0,
      currentPage: 1,
      limit: 10,
      //   snackbar
      snackbar: false,
      snackbarText: "",
      isError: false,
      //   shareByEmail
      shareJobPost: {
        id: "",
        title: "",
      },
      invitecandidate: false,
      emails: "",
      fieldRules: [(v) => !!v || "Field is required"],
      btnLoading: false,
      activeJob: null,
      showConfirmationModal: false,
      modelType: "",
      cannotDeleteModal: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    headers() {
      let arr = [
        {
          text: this.$vuetify.lang.t("$vuetify.post"),
          value: "title",
          showAll: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.location"),
          value: "location",
          showAll: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.new_profiles"),
          value: "new_applications",
          showAll: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.total_profiles"),
          value: "total_applications",
          showAll: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.hired_days"),
          value: "hired_days",
          showAll: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.status"),
          value: "status",
          showAll: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.posted_on"),
          value: "created_at",
          showAll: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.action"),
          value: "id",
          showAll: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.share"),
          value: "link",
          showAll: true,
        },
      ];

      return arr.filter(() => {
        // if (this.isAdmin) return true;
        // return a.showAll;
        return true;
      });
    },
    paginationOptions() {
      return { page: this.currentPage, itemsPerPage: this.limit };
    },
    searchable_items() {
      return [
        {
          search: "title",
          text: "Post",
        },
      ];
    },
    whereHas() {
      if (this.search_field && this.search) {
        return JSON.stringify({
          key: this.search_field,
          value: this.search,
        });
      } else {
        return false;
      }
    },
    formatedList() {
      return this.items.map((application) => {
        const {
          title,
          _id,
          applications,
          status,
          created_at,
          link,
          location,
          positions,
          hiring_manager, // interviewer
          hr_coordinator, // manager
        } = application;
        let hired_days = 0;
        let new_applications = 0;
        let joined_applications = [];
        let is_interviewer = false;
        let locations = location;

        let hired = {
          days: 0,
          joined: 0,
          positions: positions || 0,
        };

        if (applications.length) {
          new_applications = applications.filter(
            (app) => app.status.toLowerCase() === "new"
          ).length;

          joined_applications = applications.filter(
            (app) => app.status.toLowerCase() === "joined"
          );

          if (joined_applications.length) {
            joined_applications.forEach((app) => {
              if (app.hired_days) hired_days += app.hired_days;
            });

            if (hired_days) hired_days / joined_applications.length;
          }
        }

        hired.days = hired_days;
        hired.joined = joined_applications.length;
        // 2/4 days

        if (this.user._id == hiring_manager) is_interviewer = true;

        if (typeof hr_coordinator === "string") {
          is_interviewer = hr_coordinator !== this.user._id;
        } else {
          is_interviewer =
            hr_coordinator && !hr_coordinator.includes(this.user._id);
        }

        if (this.isAdmin) is_interviewer = false;

        if (locations) {
          let locArr = locations;
          if (typeof locations === "string") {
            locArr = locations.split(",");
          }

          if (locArr && locArr.length > 1) {
            locArr.splice(-1, 1);
            locations = locArr.join(", ");
          }
        }

        return {
          id: _id,
          title,
          status,
          created_at: UTC_TO_LOCAL(created_at, "ll"),
          link,
          total_applications: applications.length,
          new_applications,
          location: locations,
          hired,
          is_interviewer,
        };
      });
    },
  },
  methods: {
    moveToTitle(data) {
      const job = this.items.find((d) => d._id === data.id);
      if (job.link) {
        const domain = job.link.split("/")[0];
        const { _id } = job;
        window.open(`https://${domain}/job/${_id}`, "_blank");
      }
    },
    someChange(options) {
      const { page, itemsPerPage } = options;
      if (page !== this.currentPage || itemsPerPage !== this.limit) {
        this.currentPage = page;
        this.limit = itemsPerPage;
        this.fetchList();
      }
    },
    searchData() {
      this.currentPage = 1;
      this.fetchList();
    },
    async fetchList() {
      try {
        this.loading = true;
        const { currentPage, limit } = this;
        let url = `job?p=${currentPage}&limit=${limit}&desc=created_at&company_id=${this.user.company_id}&w=applications`; //&status=publish
        if (this.whereHas) {
          url = url + `&like=${this.whereHas}`;
        }
        const resp = await this.axios.get(url);
        const { data, count } = resp.data;
        this.totalCount = count;
        this.items = data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    shareSocialMedia(job, platform) {
      if (platform === "facebook") {
        let link = `https://www.facebook.com/sharer/sharer.php?u=${job.link}?emp_id=${this.user._id}&platform=social_media`;
        window.open(link, "_blank");
      } else if (platform === "linkedin") {
        let link = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          `${job.link}?emp_id=${this.user._id}&platform=social_media`
        )}`;
        window.open(link, "_blank");
      } else {
        this.emails = null;
        this.invitecandidate = true;
        this.shareJobPost.id = job.id;
        this.shareJobPost.title = job.title;
      }
    },
    clipboardSuccessHandler() {
      this.isError = false;
      this.snackbar = true;
      this.snackbarText = "Link Copied Successfully.";
    },
    clipboardErrorHandler() {
      this.isError = true;
      this.snackbar = true;
      this.snackbarText = "SOmething Went wrong while copying the link";
    },
    async sendCandidateInvitation() {
      try {
        if (!this.emails) return;

        this.btnLoading = true;
        const link = `${this.user.company.url}/job/${this.shareJobPost.id}?emp_id=${this.user._id}&platform=email`;
        await this.axios.post("/invite/candidate", {
          emails: this.emails,
          job: this.shareJobPost.title,
          link,
        });

        this.btnLoading = false;
        this.invitecandidate = false;
        this.emails = null;
        this.shareJobPost = { title: "", id: "" };
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    makeConfirmation(job, type) {
      if (type === "delete") {
        const { total_applications } = job;
        if (total_applications > 0) return (this.cannotDeleteModal = true);
      }
      this.activeJob = job;
      this.modelType = type;
      this.showConfirmationModal = true;
    },
    confirmHandler() {
      if (this.modelType === "edit") {
        this.editJob();
      } else {
        this.deleteJob();
      }
    },
    editJob() {
      window.open(`#/job-post/${this.activeJob.id}`, "_blank");
      this.activeJob = null;
      this.modelType = "";
      this.showConfirmationModal = false;
    },
    async deleteJob() {
      try {
        this.btnLoading = true;
        await this.axios.delete(`/job/${this.activeJob.id}`);
        this.fetchList();
        this.btnLoading = false;
        this.activeJob = null;
        this.modelType = "";
        this.showConfirmationModal = false;
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
    formatAllLocations(locations) {
      if (locations) {
        if (typeof locations === "string") {
          let locs = locations.split(",");
          if (locs.length > 2) return locs[0].trim() + ", " + locs[1].trim();
          return locations;
        }

        if (Array.isArray(locations)) {
          let locs = locations;
          if (locs.length > 2) return locs[0].trim() + ", " + locs[1].trim();
          return locations.join(", ");
        }
      }

      return locations;
    },
    showLocationsTooltip(locations) {
      if (locations) {
        if (typeof locations === "string") {
          let locs = locations.split(", ");
          if (locs.length > 2)
            return locs.splice(2, locs.length - 1).join(", ");
        }

        if (Array.isArray(locations)) {
          let locs = locations;
          if (locs.length > 2)
            return locs.splice(2, locs.length - 1).join(", ");
        }
      }
      return false;
    },
  },
  created() {
    this.fetchList();
  },
};
</script>

<style lang="scss" scoped>
.tableHeader {
  letter-spacing: 1px;
  font-weight: bold;
  background-color: #eaeef2;
  border: 1px solid #e6e6f1;
  color: #3dade5;
}
.tableSearch {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  min-height: 70px;
}
.table-row {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  td {
    color: #2f3242;
  }
}
</style>
